import React from 'react';
import { ACTIONS as UI_ACTIONS, NAVSTATES} from "../reducers/UiReducer"
import styled from 'styled-components';
import {components} from '../theme.json'
import {AppearKeyFrame, LineVerti, LineHori, OnlyLarge} from "./Theme";

const LogoContainer = styled.div`
  position: fixed;
  width:100vw;
  height: 100vh;
  z-index:100;
  pointer-events: none;
  animation: ${AppearKeyFrame};
  animation-duration: ${components.nav.logo.duration};
  animation-delay: ${components.nav.logo.start};
  opacity: 0;
  animation-fill-mode: forwards;
  `

  const LogoContainer2 = styled(LogoContainer)`
  animation: ${null};
  opacity: 1;
  `

const Line1 = styled(LineVerti)`   
    top: 0px;
    width: 100vw;
`

const Line2 = styled(LineVerti)`   
    top: ${window.innerHeight/2 + components.nav.height/2 -2}px;
    width: 100vw;
`

const Line3 = styled(LineVerti)`   
    top: ${window.innerHeight/2 - components.nav.height/2 - 1}px;
    width: 100vw;
`

const Line4 = styled(LineHori)`   
    right: 50vw;
    height: ${props => props.height ? props.height-1: null}px;   
`

const Line5 = styled(LineHori)`   
    bottom: 0px;
    right: 50vw;
    height: ${props => props.height ? props.height: null}px;
`

const Logo = () => {
    return (
        <LogoContainer>
            <OnlyLarge>
            <Line1 />
            {/* <Line2 width={3}/>
            <Line3 width={3}/> */}
            <Line2 />
            <Line3 />
            <Line4 height={window.innerHeight/2 - components.nav.height/2} />
            <Line5 height={window.innerHeight/2 - components.nav.height/2} />
            </OnlyLarge>
        </LogoContainer>
    );
}

export const NakedLogo = ()=>{
    return (<LogoContainer2>
    <OnlyLarge>
            <Line1 />
            {/* <Line2 width={3}/>
            <Line3 width={3}/> */}
            <Line2 />
            <Line3 />
            <Line4 height={window.innerHeight/2 - components.nav.height/2} />
            <Line5 height={window.innerHeight/2 - components.nav.height/2} />
            </OnlyLarge></LogoContainer2>)
}




export default Logo;
