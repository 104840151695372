import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch} from "react-redux";
import styled, {keyframes} from 'styled-components';
import {components, padding, medias} from '../theme.json'
import {Grid, Col, Row, Section, Text, OnlySmall, OnlyLarge, Line, TextSpan, DivideLine} from "../components/Theme"
import Slider from "react-slick";
import left from '../assets/icons/arrow-left.svg';
import right from '../assets/icons/arrow-right.svg';
import escape from '../assets/icons/escape.svg';
import useKeyPress from '../hooks/useKeyPress';
import { useSelector} from "react-redux";
import { STATES as DATA_STATES, ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import { ACTIONS as UI_ACTIONS, NAVSTATES} from "../reducers/UiReducer"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import VisibilitySensor  from 'react-visibility-sensor';
import { Redirect } from 'react-router';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once

const Footer = styled.span`
    position: fixed;
    bottom: ${components.nav.height*1.5}px;
    @media ${medias.onlySmall} {
        bottom: ${components.nav.height*.8}px;
    }
    width: 99%;
    z-index:0;
`

const SpacerSpacerSpacer = styled.div`
    height: 2vh; 
    @media (min-width: 1200px) {
        height: 3vh; 
    }
    @media (min-width: 1800px) {
        height: 1vh; 
    }
    @media (min-width: 2000px) {
        height: 2vh; 
    }
    @media (max-width: 640px) {
        height: 5vh; 
    }
`

const Escape = styled.img `
    position: absolute;
    top: 100px;
    width: ${components.icon.height}px;
    right: 22px;
`

const FooterButtons = styled.div`
    position: absolute;
    bottom: -40px;
`

const FBLeft = styled(FooterButtons)`
    left: ${padding.default}px;
`


const FBRight = styled(FooterButtons)`
    right: 46px;
    text-align: right;   
`

const Immer = styled.img`
   width: ${components.icon.height * 1.3}px
`

const Spacer = styled.div`
    height: 5px;
`

const SliderPusher = styled.div`
    height: 10vh;
`

const RiseUp = keyframes`
  from {bottom: -20px}
  to {bottom: 70px}
`;


const SmallContainer = styled.div`
    position: fixed;
    bottom: 70px;
    width: 100%;
    // animation: 1s ease-out 1000ms 1 ${RiseUp};
    // animation-fill-mode: forwards;
`

const Image = (props) => {    
    const data = useSelector(state => state.data);
    
    const images = props.images;
    const pescape = props.escape;
    
    let goEscape=useCallback(()=>{pescape()}, [pescape]);
    const happyPress = useKeyPress('h');
    const leftPress = useKeyPress('ArrowLeft');
    const rightPress = useKeyPress('ArrowRight');
    const escapePress= useKeyPress('Escape');
    const ui = useSelector(state => state.ui);
    const [pushBack, setPushBack] = useState(false);
    const [lastChange, setLastChange] = useState(new Date());
    const [doKey, setDoKey] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    
    const dispatch = useDispatch();

    const updateStateIndex = (number)=>{
        console.log(number);
        let currtime = new Date();
        if (currtime - lastChange > 600){
            let curridx = data.imageIndex;
            curridx = number;
            if (curridx >= images.length){
                curridx = 0;
            }else if (curridx < 0){
                curridx = images.length-1;
            }
            dispatch({type: DATA_ACTIONS.SELECT_IMAGE, index:curridx});
            setLastChange(currtime);
        } else {
            // console.log('too slow')
        }
    }

    useEffect(() => {
        
        if (doKey){
            if (leftPress){
                updateStateIndex(data.imageIndex-1)
            } else if (rightPress){
                updateStateIndex(data.imageIndex+1)
            }
        }

        if (escapePress){
            goEscape();
        }

        switch (ui.navstate) {
            case NAVSTATES.DEFAULT:
                setTimeout(() => {
                    setPushBack(false);
                }, 1000);
                break;
            default:
                setPushBack(true);
                break;
        }
    }, [leftPress, rightPress, escapePress, ui.navstate, goEscape, data.imageIndex, images]);

    

    let slc2 = props.images.map((image, idx)=>{
        return (
        <div key={idx} 
            style={{height: window.innerHeight-300+"px", position: "relative", background:"white"}}
            onClick={() => setIsOpen(image.url)}
            >
            <img style={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                overflow: "auto",
                margin: 'auto',
                position: "absolute",
                top: 0, 
                left: 0, 
                bottom: 0, 
                right: 0,
                border: "solid white",

            }} src={image.url}/>
        </div>
        )
    })

    const counter = (<Section>
        <OnlySmall>
            <Text align="right">
                {data.imageIndex+1} / {props.images.length}
            </Text>
        </OnlySmall>
        <OnlyLarge>
        <TextSpan align="right">
            {data.imageIndex+1} / {props.images.length}
        </TextSpan>
        </OnlyLarge>
        </Section>
        )

    const renderSlider = (
        <Section >
        <Grid style={{margin: 12, left:0, paddingLeft: "0px", paddingRight:"200px", width: (window.innerWidth-24)+"px", position: "fixed", zIndex: (pushBack||ui.navstate !== NAVSTATES.DEFAULT)?-1:1}}>
            <Row center="xs">
                <Col xs={12} style={{height: components.nav.height}}></Col>
                <Col xs={12} style={{height: "40px"}}></Col>
                <Col xs={12}>
                    <SpacerSpacerSpacer>&nbsp;</SpacerSpacerSpacer>
                </Col>
                <Col xs={12} sm={8} md={8} lg={6} xl={4} style={{zIndex: pushBack?-1:1}}>
                    <Carousel style={{zIndex: pushBack?-1:1, width: "100%"}}
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                    selectedItem={data.imageIndex}
                    onChange={(index_)=>{
                        if (index_ !== data.imageIndex){
                            dispatch({type: DATA_ACTIONS.SELECT_IMAGE, index:index_});
                        }
                    }}
                        >
                    {slc2}
                    </Carousel>
                </Col>
            </Row>
        </Grid>
    <OnlyLarge>
    <Grid style={{margin: 12, left:0, bottom: "30px", paddingLeft: "0px", paddingRight:"200px", width: (window.innerWidth-24)+"px", position: "fixed", zIndex: pushBack?-1:1}}>
            <Row>
                <Col xs={1} sm={2} md={2} lg={3} xl={4} style={{zIndex: pushBack?-1:1}}></Col>
                <Col xs={1} sm={3} md={4} lg={3} xl={2} style={{zIndex: pushBack?-1:1}}>
                    <div style={{width: "100%", marginLeft: "3px"}}>
                    <Text>{props.images[data.imageIndex%images.length].description}</Text>    
                    </div>
                </Col>
                <Col xs={1} sm={5} md={4} lg={3} xl={2} style={{zIndex: pushBack?-1:1}}>
                    <div style={{width: "100%", textAlign:"right"}}>
                    <Text>{data.imageIndex+1} / {props.images.length}</Text>    
                    </div>
                </Col>
                <Col xs={1} sm={1} md={2} lg={3} xl={4} style={{zIndex: pushBack?-1:1}}>
                </Col>
            </Row>
        </Grid>
        
        
        <div style={{position: "fixed", left: "22px", bottom: "30px"}}>
            <Immer src={left} onClick={()=>{updateStateIndex(data.imageIndex-1)}}/>
        </div>
        <div style={{position: "fixed", right: "22px", textAlign:"right", bottom: "30px"}}>
            <Immer src={right} onClick={()=>{updateStateIndex(data.imageIndex+1)}}/>
        </div>
    </OnlyLarge>
    </Section>)

    if (isOpen){
        return (
            
                <Lightbox
                    mainSrc={isOpen}
                    
                    
                    onCloseRequest={() => setIsOpen(false)}
                    
                />
            )
    
    }

    return (
        <Section>
            <VisibilitySensor onChange={(visible)=>{
                        console.log(visible);
                    setDoKey(visible)
                    }}><div>&nbsp;</div></VisibilitySensor>
            <OnlyLarge>
                <SliderPusher />
            </OnlyLarge>
            <OnlySmall>
                {renderSlider}
            </OnlySmall>
            <OnlyLarge>
                
                    {renderSlider}
                
            </OnlyLarge>
            
            
                <Footer>
                    {ui.navstate == NAVSTATES.DEFAULT ?
                    <OnlySmall>
                        <SmallContainer>
                            <div style={{position: "absolute", width: "100%"}}>
                            {/* <Line style={{transform: "translate(-15px, -13px)", width:"120vw"}}/> */}
                            <FBLeft style={{transform: "translate(0px, 10px)"}}>
                                <Text>{props.images[data.imageIndex%images.length].description}</Text>
                                <Spacer />
                                <Immer src={left} onClick={()=>{updateStateIndex(data.imageIndex-1)}}/>
                            </FBLeft>
                            <FBRight style={{transform: "translate(0px, 12px)"}}>
                                {counter}
                                <Spacer />
                                <Immer src={right} onClick={()=>{updateStateIndex(data.imageIndex+1)}}/>
                            </FBRight> 
                            </div>
                        </SmallContainer>
                    </OnlySmall> : null}
                    {ui.navstate !== NAVSTATES.ABOUT ?
                    <OnlyLarge style={{position: "fixed", left: '0px', bottom: "12px", width: "100vw"}}>
                        <Line style={{marginLeft: padding.default*1.5+"px",marginRight: padding.default*1.5+'px'}}/>
                        <DivideLine height={components.project.footerHeight}/>
                    </OnlyLarge>:null}
                </Footer> 
            <OnlyLarge>
            {happyPress && '😊'}
                <Escape src={escape} alt='escape' style={{zIndex : (ui.navstate !== NAVSTATES.ABOUT)?100:0}} onClick={()=>{
                    dispatch({type: UI_ACTIONS.SET_CHANGE_PAGE, changed: true});
                    setTimeout(() => {
                        dispatch({type: UI_ACTIONS.SET_CHANGE_PAGE, changed: false});
                    }, 1000);
                    goEscape()
                    }}/>
            </OnlyLarge>
        </Section>
        
    )
}

export default Image;
