import React, {useEffect, useState, useCallback, useRef} from 'react';
import ReactDOM from 'react-dom'
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch} from "react-redux";
import useHover from '@react-hook/hover'
import useKeyPress from '../hooks/useKeyPress';
import styled, {keyframes} from 'styled-components';
import Masonry from 'react-masonry-component';
import {Text, Line, LineHori, TextSpan, Section, OnlySmall, OnlyLarge, Bolder, DivideLine} from "../components/Theme"
import {components, padding, medias} from '../theme.json'
import Loader from '../components/Loader'
import { STATES as DATA_STATES, ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import Chance from 'chance';
import left from '../assets/icons/arrow-left.svg';
import right from '../assets/icons/arrow-right.svg';
import Image from './Image';
import VisibilitySensor  from 'react-visibility-sensor';


const Container = styled.div`
    padding-top: 100px;
    z-index: -2;
    width: 100%;
`

const Immer = styled.img`
    width: 100%;
    height: auto;
    max-height: 29vw;
    object-fit: contain;
    object-position: 0% 50%;
    align: left;
`

const ImmerIcon = styled.img`
   width: ${components.icon.height * 1.3}px
`

const Wrapper = styled.div`
    width: ${props=>props.width?props.width:33}%;
    padding: ${props=>props.padding?props.padding:35}px; 
    margin-bottom: ${props=>props.margin}px; 
    box-sizing: border-box;    
    align: left;
`

const RiseUp = keyframes`
  from {bottom: ${components.project.pos};}
  to {bottom: 0px;}
`;

const GoDown = keyframes`
  from {bottom: 0px;}
  to {bottom: ${components.project.pos};}
`

const Footer = styled.div`
    width: 100%;
    height: ${components.project.footerHeight}px;
    background: white;
    z-index:100;
    position: fixed;
    left: 0px;
    bottom: ${props=>(props.appear)?components.project.pos:"0px"};
    animation: ${props=>props.appear?RiseUp:GoDown};
    animation-duration: ${props=>props.early?'1ms':components.nav.projectfooter.duration};
    animation-fill-mode: forwards;
    animation-delay: 0s;
    &:hover {
      animation: ${RiseUp};
      animation-fill-mode: forwards;
    }
    visibility: ${props=>props.early?"hidden":"visible"}
`

const FakeFooter = styled.div`
    width: 100%;
    height: ${components.project.footerHeight}px;
    background: white;
    z-index:100;
    position: fixed;
    left: 0px;
    bottom: ${components.project.pos};
`

const FooterButtons = styled.span`
    position: absolute;
    bottom: ${components.project.footerHeight/3}px;
`

const FBLeft = styled(FooterButtons)`
    position: absolute;
    left: ${padding.default*2.5}px;
`

const FBRight = styled(FooterButtons)`
    position: absolute;
    right: ${padding.default*2}px;
    text-align: right;   
`

const HelloText = keyframes`
  0% {transform:translate(0, -30px);}
  100% {transform:translate(0, 0px);}
`

const ByText = keyframes`
  from {transform:translate(0, 0px);}
  to {transform:translate(0, -30px);}
`

const TextContainer = styled.div`
    transform: translate(0, -30px);
    position: absolute;
    z-index: -10;
`

const TextContainerHovered = styled(TextContainer)`
    transform: translate(0, 0);
    animation: ${HelloText};
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 0s;
`

const TextContainerHoveredReturn = styled(TextContainer)`
    transform: translate(0, 0);
    animation: ${ByText};
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 0s;
`

const ImageHover = props => {
    const chance = new Chance(props.index+props.seed);
    const [isHovering, ref] = useHover(200, 200)
    const [wasHovering, setWasHovering] = useState(false);
    const defaultPadding = window.innerWidth / 20;
    const padding = Math.floor(chance.integer({ min: defaultPadding/2, max: defaultPadding}));
    const margin = Math.floor(chance.integer({ min: defaultPadding/2, max: defaultPadding*2}));

    useEffect(() => {
      if (isHovering !== wasHovering){
        if (!isHovering){
          setTimeout(() => {
            setWasHovering(isHovering);
          }, 1000);
        } else {
          setWasHovering(isHovering);
        }
      }
    });

    let inner = ( <Text>{props.description}</Text> )
    let outer = null;

    if (isHovering){
      outer = <TextContainerHovered>{inner}</TextContainerHovered>
    } else if (wasHovering){
      outer = <TextContainerHoveredReturn>{inner}</TextContainerHoveredReturn>
    } else {
      outer = <TextContainer>{inner}</TextContainer>
    }



    return <Wrapper ref={ref} padding={padding} width={33} margin={margin} onClick={props.clicker} 
                    style={{transform: "translate("+chance.integer({ min: -defaultPadding, max: defaultPadding})+"px, 0)"}}>
                <Immer src={props.url+"?w="+Math.floor(window.innerWidth/3)} alt={props.description} onContextMenu={(e)=> e.preventDefault()} onDragStart={(e)=> e.preventDefault()}/>
                {outer}
        </Wrapper>
  }

export const Project = ({project}) => {
    const data = useSelector(state => state.data);
    const ui = useSelector(state => state.ui);
    // const [imageSelect, setImageSelect] = useState(null);
    const [redirecter, setRedirecter] = useState(null);
    const [scrollto, setScrollto] = useState(null);
    const [showBottomMenu, setShowBottomMenu] = useState(false);
    const [isEarly, setIsEarly] = useState(true);
    const leftPress = useKeyPress('ArrowLeft');
    const rightPress = useKeyPress('ArrowRight');
    const dispatch = useDispatch();
    

    const setImageSelect = (index)=>{
      dispatch({type: DATA_ACTIONS.SELECT_IMAGE, index});
    }

    const masonryOptions = {
        transitionDuration: 0
    }

    let handleLayoutComplete = (laidOutItems) =>  {
      // setShouldBlock(false);
    }

    const getConstrainedIndex = useCallback((oldIndex)=>{
      let length = data.projects.length;
      if (oldIndex < 0){
        oldIndex = length-1;
      } else if (oldIndex >= length){
        oldIndex = 0;
      }
      return oldIndex;
    },[data])

    const getProject=useCallback((adder)=>{
      let index = getConstrainedIndex(data.selectProject+adder);
      return data.projects[index].name;
    }, [data, getConstrainedIndex]);

    const imageContent = project.pictures.map((img, i) => {
      return (
        <ImageHover index={i} key={i} early={isEarly} seed={project.randomseed || 123} url={img.url} description={img.description} clicker={()=>{
          dispatch({type: DATA_ACTIONS.SELECT_IMAGE, index:i});
          setScrollto(window.pageYOffset);
        }
        } />
    )});

    useEffect(() => {
      if (data.selectProject!==null && data.projects[data.selectProject] === project && redirecter !==null){
        setRedirecter(null);
      } 

      if (leftPress){
        setTimeout(() => {
          setRedirecter('/project/'+getProject(-1).toLowerCase())  
        }, 300);
        
      }
      if (rightPress){
        setTimeout(() => {
          setRedirecter('/project/'+getProject(+1).toLowerCase())
        }, 300);
      }

      setTimeout(() => {
        setIsEarly(false);
      }, 500);

    }, [data.selectProject, data.projects, project, redirecter, leftPress, rightPress, getProject]);

    // {redirecter!==null?
    // <Redirect to={redirecter} />

    const smallContent = (()=>{
      if (data.selectProject !== null){
        return <Image images={data.projects[data.selectProject].pictures} escape={()=>{
          setImageSelect(null)
        }}/>
      } else {
        return null;
      }
    })()

    const footerContent = (()=>{
      if (data.selectProject !== null ){
        
        return (
        <Section>
          {ui.changedPage?
        <FakeFooter>
            <Line style={{marginLeft: padding.default*1.5+"px",marginRight: padding.default*1.5+'px'}}/>
                  <DivideLine height={components.project.footerHeight}/>
          </FakeFooter>:null}
        <Footer appear={showBottomMenu} early={isEarly} style={{visibility: ui.changedPage?"hidden":"visible"}}>
                  <Line style={{marginLeft: padding.default*1.5+"px",marginRight: padding.default*1.5+'px'}}/>
                  <DivideLine height={components.project.footerHeight}/>
                  <Link to={'/project/'+getProject(-1).toLowerCase()} onClick={()=>{;window.scrollTo(0, 0);}}>
                    <FBLeft>
                      <ImmerIcon src={left} />&nbsp;&nbsp;
                      <TextSpan> 
                        <Bolder>
                          {getProject(-1)}
                        </Bolder>
                      </TextSpan>
                    </FBLeft>
                  </Link>  
                  <Link to={'/project/'+getProject(+1).toLowerCase()} onClick={()=>{window.scrollTo(0, 0);}}>
                    <FBRight>
                      <TextSpan> 
                      <Bolder>
                        {getProject(+1)}
                        </Bolder>
                        </TextSpan>&nbsp;&nbsp;
                      <ImmerIcon src={right}/>
                    </FBRight>
                  </Link>
                </Footer></Section>)
      } else {
        return null;
      }
    })()

    const largeContent = (()=>{
      if (data.imageIndex !== null){
        return <Image images={data.projects[data.selectProject].pictures} escape={()=>{
          setImageSelect(null);
          setIsEarly(true);
          setTimeout(() => {
            setIsEarly(false);
          }, 500);
        }}/>
      } else if (redirecter !== null && window.innerWidth > medias.small){
        return <Redirect to={redirecter} />
      } else {

        if (scrollto){
          setTimeout(() => {
            window.scrollTo(0, scrollto)  
            setScrollto(null);
          }, 100);
        }

        return (
          <Section>
            {/* {shouldBlock?<Logo />:null} */}
            <Container>
            <Masonry style={{margin: "100px auto", marginTop:"0px", width: "94vw"}}
                    // ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
                    elementType={'div'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    onLayoutComplete={laidOutItems => handleLayoutComplete(laidOutItems)}
                    updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                    >
                <Wrapper>
                    <Text style={{marginTop: "14px"}}>
                        {project.description}
                    </Text>
                </Wrapper>
                {imageContent}
              </Masonry>
              {footerContent}
            </Container>
          </Section>
        )
      }
    })()

    return (
        <Section>
          <Section>
            <OnlyLarge>
              {largeContent}
            </OnlyLarge>
          <OnlySmall>
            {smallContent}
          </OnlySmall>
          </Section>
          <VisibilitySensor onChange={(visible)=>{setShowBottomMenu(visible)}}>
            <div>&nbsp;</div>
          </VisibilitySensor>
        </Section>
        
)}

const ProjectQuery = (props)=>{
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.fetchstatus === DATA_STATES.DONE){
      const slug = props.match.params.slug;
      let selectedindex = props.targetIndex || 0;
      
      if (slug){
        data.projects.forEach((project, idx)=>{
          if (project.name.toLowerCase() === slug.toLowerCase()){
            selectedindex = idx;
          }
        })
      }
      
      
      if (data.selectProject !== selectedindex){
        dispatch({type: DATA_ACTIONS.SET_SELECTED_PROJECT, index: selectedindex});
      }
    } 
  });

  return (data.fetchstatus===DATA_STATES.DONE && data.selectProject !== null  )?
    <Project project={data.projects[data.selectProject]}/>
    : <Loader />
}

export default ProjectQuery;

