export const ACTIONS = Object.freeze({
    FETCH: Symbol("data/fetch"),
    SET: Symbol("data/set"),
    ERROR: Symbol("data/error"),
    SET_SELECTED_PROJECT: Symbol("data/selectProject"),
    SELECT_IMAGE: Symbol("data/selectSymbol"),
    SET_REF: Symbol("data/ref")
});

export const STATES = Object.freeze({
    UNIN:   Symbol("unin"),
    BUSY:  Symbol("busy"),
    DONE: Symbol("done"),
    ERROR: Symbol("error")
});

const initialState = {
  fetchstatus: STATES.UNIN,
  projects: [],
  about: {},
  selectProject: null,
  imageIndex: null,
  lastProjectImageRef: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH: {
      return {
        ...state,
        fetchstatus: STATES.BUSY
      };
    }
    case ACTIONS.SET: {
        return {
            ...state,
            fetchstatus: STATES.DONE,
            projects: action.data.projects,
            about: action.data.about
        };
      }
    case ACTIONS.ERROR: {
        return {
            ...state,
            fetchstatus: STATES.ERROR
        };
    }
    case ACTIONS.SET_SELECTED_PROJECT: {
        return {
            ...state,
            imageIndex: null,
            selectProject: action.index
        };
    }
    case ACTIONS.SELECT_IMAGE: {
      return {
        ...state,
        imageIndex: action.index
      }
    }
    case ACTIONS.SET_REF: {
      return {
        ...state,
        lastProjectImageRef: action.ref
      }
    }
    default: {
      return { ...state };
    }
  }
};
