import React, { useRef, useState, useEffect } from 'react';

// import { FiChevronRight } from 'react-icons/fi'; // Example using react-icons
import { GrNext as RightIcon } from "react-icons/gr";


import { Link } from 'react-router-dom';
import Loader from '../components/Loader'
import { useSelector, useDispatch} from "react-redux";
import { STATES as DATA_STATES } from "../reducers/DataReducer"
import { ACTIONS as UI_ACTIONS, NAVSTATES} from "../reducers/UiReducer"
import {Title, FullWidth, Center, OnlySmall, OnlyLarge, Section, ScrollIcon, EdgeSpace, VertHort, UnderlinderSpan, Line, NavigationContainer} from './Theme'

function ProjectNavigation({ projects }) {
    const scrollContainerRef = useRef(null);
    const [showScrollIcon, setShowScrollIcon] = useState(false);
  
    useEffect(() => {
      const checkOverflow = () => {
        const container = scrollContainerRef.current;
        if (!container) return;
        const isOverflowing = container.scrollWidth > container.clientWidth;
        setShowScrollIcon(isOverflowing);
      };
  
      checkOverflow();
      window.addEventListener('resize', checkOverflow);
  
      return () => window.removeEventListener('resize', checkOverflow);
    }, [projects]);
  
    const scrollRight = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
      }
    };
  
    return (
      <div>
        
        
        <NavigationContainer ref={scrollContainerRef}>
        <EdgeSpace style={{width: "20px"}}>&nbsp;</EdgeSpace>
          {projects.map((project, index) => (
            <EdgeSpace key={index}>
              <UnderlinderSpan>{project}</UnderlinderSpan>
            </EdgeSpace>
          ))}
        </NavigationContainer>
        {showScrollIcon && (
          <ScrollIcon onClick={scrollRight}>
            <RightIcon size={21} />
          </ScrollIcon>
        )}
      </div>
    );
  }

const ProjectList = (props) => {
    let projects = props.projects;
    const dispatch = useDispatch();

    projects = projects.map((project)=> 
        <Link to={'/project/'+project.name.toLowerCase()} onClick={
            ()=>{dispatch({ type: UI_ACTIONS.SET_NAV,  nav: NAVSTATES.DEFAULT});}
        }>
            <Title>
                {project.name}
            </Title>
        </Link>);

    let projectsSmall = projects.map((project, index)=>
        <FullWidth key={index}>
            <UnderlinderSpan>
                {project}
            </UnderlinderSpan>
            <br/><br/><br/>
        </FullWidth>
        )
    // let projectsLarge = projects.map((project, index)=>
    //     <EdgeSpace key={index}>
    //         <UnderlinderSpan>
    //             {project}
    //         </UnderlinderSpan>
    //     </EdgeSpace>
    // )
    return (
        <Section>
            <OnlySmall>
                <Center>
                    <br/>
                    {projectsSmall}
                    <Line />
                </Center>
            </OnlySmall>
            <OnlyLarge>
                <VertHort>
                    {/* <NavigationContainer>
                        {projectsLarge}
                    </NavigationContainer> */}
                    <ProjectNavigation projects={projects} />
                </VertHort>
            </OnlyLarge>
        </Section>
    );
}

const ProjectListQuery = (props) => {  
    const data = useSelector(state => state.data);
    if (data.fetchstatus === DATA_STATES.DONE){
        return (
            <ProjectList projects={data.projects}/>
        );
    } else {
        return <Loader />
    }
}


export default ProjectListQuery;
