import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import {Text, Row, Col, Grid, SubTitle, List, ListElement, OnlySmall, Line, Section, OnlyLarge} from './Theme'
import Loader from './Loader'
import { useSelector} from "react-redux";
import { STATES as DATA_STATES } from "../reducers/DataReducer"
import { ACTIONS as UI_ACTIONS, NAVSTATES} from "../reducers/UiReducer"
const About = ({content, clients, contacts}) => {
    const ui = useSelector(state => state.ui);

  let clientsRendered = clients.map(
    (client)=>
        <ListElement key={client._key}>
            <a href={client.url} target="_blank" rel="noopener noreferrer">
                <Text>
                    {client.name}
                </Text>
            </a>
        </ListElement>)

  let contactsRendered = contacts.map((contact)=>
      <ListElement key={contact._key}> 
          <a href={contact.url} target="_blank" rel="noopener noreferrer">
              <Text>
                  {contact.name}
              </Text>
          </a>
      </ListElement>)
    
    return (
        <Section>
        <Grid style={{maxWidth: "100vw"}}>
            <br/>
            <Row>
                <Col xs={12} md={6}>
                    <Text style={{lineHeight: "22px"}}>
                        {(typeof content == 'string')? content : <BlockContent blocks={content} style={{lineHeight: "500px"}} />}
                    </Text>
                    <br/>
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <SubTitle>
                        Selected Clients
                    </SubTitle>
                    <List>
                        {clientsRendered}
                    </List>
                    <br/>
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <SubTitle>
                        Contact
                    </SubTitle>
                        <List>
                            {contactsRendered}
                        </List>
                        <br/>
                    <SubTitle>
                        Credits
                    </SubTitle>
                    <List>
                        <ListElement>
                            <Text>
                                design: <a href='https://broosstoffels.be/'>Broos Stoffels</a>
                            </Text>
                        </ListElement>
                        <ListElement>
                            <Text>
                            website:  <a href='https://pietersteyaert.net'>Pieter Steyaert</a>
                            </Text>
                        </ListElement>
                    </List>
                    <br/>
                    <br/>
                </Col>
            </Row>
        </Grid>
        <OnlyLarge>
            <Line />
        </OnlyLarge>
        <OnlySmall>
            <br/><br/>
        </OnlySmall>
        </Section>    
    );
}

const AboutQuery = () => {
    
  const data = useSelector(state => state.data);

    if (data.fetchstatus === DATA_STATES.DONE){
      return (
          <About content={data.about.content} clients={data.about.clients} contacts={data.about.contacts}/>
      );
    } else {
      return <Loader />
    }

    
}

export default AboutQuery;



