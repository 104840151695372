import sanityClient from '@sanity/client'
import envi from './environment'
import {
    settings
} from './connector_settings.json'


export const client = () => {
    const environment = envi();
    let _ = settings;
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: true
    })

    const projectsfetch = sc.fetch(_.projectsq);
    const aboutfetch = sc.fetch(_.aboutq);

    const data = {};

    // if (environment.dev){
    //     environment.printstatus()
    // } 

    const getData = (options) => {
        options = options || {};
        options.forceMockup = options.forceMockup || false;
        return new Promise((resolve, reject) => {
            const resolver = (data, mockup) => {
                let ob = {
                    about: data[0],
                    projects: data[1].projects
                };
                if (mockup) {
                    console.log('using mockup data');
                    setTimeout(() => {
                        resolve(ob);
                    }, 600);
                } else {
                    resolve(ob);
                }
            }

            if (((!environment.dev || JSON.parse(_.forceConnection)) && !options.forceMockup)) {
                if (data.aboutfetch && data.projectsfetch) {
                    console.log('I saved')
                    resolver([data.aboutfetch, data.projectsfetch]);
                } else {
                    Promise.all([aboutfetch, projectsfetch])
                        .then((data) => {
                            data.aboutfetch = data[0];
                            data.projectsfetch = data[1];
                            resolver(data, false);
                        })
                        .catch((error) => {
                            // rely on mockupdata
                            if (environment.dev) {
                                console.log(error);
                                reject(error);
                            } else {
                                resolver(_.mockupdata, true);
                            }
                        })

                }
            } else {
                resolver(_.mockupdata, true);
            }
        })
    }

    return {
        status: () => console.log('i am status'),
        getData: getData
    }
}

export default client;