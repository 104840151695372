import styled, {css, keyframes} from 'styled-components';
import {medias, padding, components, colors, effects, lineSize} from '../theme.json'
import {Grid as Grid_, Col as Col_, Row as Row_} from 'react-styled-flexboxgrid';

/**
 * Keyframes
 */
export const AppearKeyFrame = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

export const DisAppearKeyFrame = keyframes`
  from {opacity: 1.0}
  to {opacity: 0}
`;

/**
 * Grid
 */
export const Grid   = Grid_;
export const Col    = Col_;
export const Row    = Row_;

/**
 * Containers
 */
export const MainContainer = styled.div`
  @media ${medias.onlySmall} {
    margin-left: ${padding.default}px;
    margin-right: ${padding.default}px;
  }
  margin: 0px;
  height: 100vh
`



export const Section = styled.div``

export const FullWidth = styled.div`
  width: 100%;
 
`
export const CenterDiv = styled.div`
  margin: auto;
  width: 50%;
`

export const FullHeight = styled.div`
  height: 100vh;
  color: ${colors.primary}
  z-index: -1;
`

export const Background = styled.div`
  background: ${colors.primary}
`

export const VerticalCenter = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`

/**
 * Media
 */
export const OnlySmall = styled.div`
  @media ${medias.onlySmall} {
    display: inline;
  }
  display: none;
`
export const OnlyLarge = styled.div`
  @media ${medias.onlySmall} {
    display: none;
  }
  display: inline;
`

export const FullWidthImage = styled.img`
  width: 100%;
`

/**
 * Typography
 */

export const underliner = css`
border-bottom: 2px solid white;
-webkit-transition: border-bottom ${effects.underlinetime} ease-in-out;
-moz-transition: border-bottom ${effects.underlinetime} ease-in-out;
-ms-transition: border-bottom ${effects.underlinetime} ease-in-out;
-o-transition: border-bottom ${effects.underlinetime} ease-in-out;
transition: border-bottom ${effects.underlinetime} ease-in-out;
&:hover {
  border-bottom: 2px solid ${colors.textPrimary};
}
`

export const Text = styled.div`  
  font-family: 'Futura LT Book', Arial, Helvetica, sans-serif;
  font-size: .87em;
`

export const TextSpan = styled.span`
  font-family: 'Futura LT Book', Arial, Helvetica, sans-serif;
  font-size: .95em;
`

export const Bolder = styled(TextSpan)`
${underliner}
font-family: 'Futura LT Regular', Arial, Helvetica, sans-serif;
font-size: 1.0em;
`

export const UnderlinderSpan = styled.span`
  ${underliner}
`

export const NavigationContainer = styled.div`
  
  position: relative;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  // align-items: center;
  scroll-behavior: smooth;
  // text-align: center;
  // justify-content: center; // Always center
  width: 100%;

  // Hiding scrollbar for Webkit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // Hiding scrollbar for Firefox
  scrollbar-width: none;

  // For IE and Edge
  -ms-overflow-style: none;
`;

export const EdgeSpace = styled.div`
  display: inline-block;
  margin-right: ${padding.default*3}px; // spacing between items

  &:last-child {
    margin-right: 0;
  }
`;


// export const EdgeSpace = styled.span`
//   margin-left: ${padding.default*2}px;
//   margin-right: ${padding.default*2}px;
// `

export const ScrollIcon = styled.div`
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-43%);
  cursor: pointer;
  z-index: 10; // Make sure this is above other content
`;


export const SubTitle = styled.div`
  font-family: 'Futura LT Regular', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.06em;
`

export const Title = styled.span`
  font-family: 'Futura LT Medium', Arial, Helvetica, sans-serif;
  font-size: 25.2px;
  @media ${medias.onlySmall} {
    font-size: 26px;
    letter-spacing: -0.4px
  }
`

export const List = styled.ul`
  margin-top: ${padding.default/2}px;
`
export const ListElement = styled.li`
  margin-bottom: ${padding.default/4}px;
` 

export const Center = styled.div`
  text-align: center;
`
export const VertHort = styled.span`
  line-height: ${components.nav.height}px;
  text-align: center;
`
export const Line = styled.div`
    color: black;
    border-bottom: ${props=>props.width?props.width:lineSize}px solid black;
`

export const LineVerti = styled(Line)`
    position: absolute;
`

export const LineHori = styled(LineVerti)`
    position: fixed;
    border-right: ${lineSize}px solid black;
`

export const DivideLine = styled(LineHori)`   
    right: 50vw;
    height: ${props => props.height ? props.height: null}px;   
`

/**
 * Icons
 */
export const Icon = styled.div`
  width: ${components.icon.height}px;
  -webkit-transition: transform ${effects.underlinetime} ease-in-out;
  -moz-transition: transform ${effects.underlinetime} ease-in-out;
  -ms-transition: transform ${effects.underlinetime} ease-in-out;
  -o-transition: transform ${effects.underlinetime} ease-in-out;
  transition: transform ${effects.underlinetime} ease-in-out;
  transform: rotate(0deg);
  &:hover {
    transform: rotate(90deg);
  }
  margin: auto;
`
