import React, {useEffect} from 'react';
import { Provider } from "react-redux";
import Store from "./Store";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';

import {MainContainer} from './components/Theme'
import Navigation from "./components/Navigation";

import HomeQuery from './pages/Home';
import ProjectQuery from './pages/Project';

import client from './services/client';
import Scrollable from 'hide-scrollbar-react';

import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS, STATES as DATA_STATES } from "./reducers/DataReducer"

import ReactGA from 'react-ga';
ReactGA.initialize('UA-125805303-1');
ReactGA.pageview(window.location.pathname + window.location.search);


/**
 * FIXES
 * 
 *  v footer menu on panel open
 *  v menu left / right make button full side and not just word
 *  v slider pixel fix
 */

window.addEventListener("resize", ()=>{
  function is_touch_device() {  
    try {  
      document.createEvent("TouchEvent");  
      return true;  
    } catch (e) {  
      return false;  
    }  
  }
  if (!is_touch_device()){
    window.location.reload(false);
  }
});

const DataHelper = () => {
  const data = useSelector(state => state.data);
  
  const cli = client();
  const dispatch = useDispatch();
  useEffect(() => {
    const error = data.fetchstatus === DATA_STATES.ERROR;
    if (data.fetchstatus === DATA_STATES.UNIN || error ){
        dispatch({ type: DATA_ACTIONS.FETCH });
        cli.getData({forceMockup: error}).then((data)=>{dispatch({ type: DATA_ACTIONS.SET, data})});
    }
  })
  return (
    null
  )
}

const history = createBrowserHistory()
function App() {

  return (
    <Provider store={Store}>
      {/* <Scrollable> */}
      <MainContainer>
        <Router history={history}>
          <DataHelper/>
          <Navigation />
          <Switch>
            <Route exact path="/" component={HomeQuery} />
            <Route exact path="/project/:slug" component={ProjectQuery} />
          </Switch> 
        </Router>
      </MainContainer>
      {/* </Scrollable> */}
    </Provider>
  );
}

export default App;
