import React, {useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import Chance from 'chance';
import {Project} from '../pages/Project'
import {Section, Line, OnlySmall, OnlyLarge, FullWidth, AppearKeyFrame, DisAppearKeyFrame} from "../components/Theme";
import {components, lineSize} from "../theme.json";
import Loader from '../components/Loader'
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch} from "react-redux";
import { ACTIONS as DATA_ACTIONS, STATES as DATA_STATES } from "../reducers/DataReducer"
import { ACTIONS as UI_ACTIONS, NAVSTATES} from "../reducers/UiReducer"
import Logo from '../components/Logo'

const slideOutLargeK = (sign1, sign2) => keyframes`
  from {transform: translate(0, 0);}
  to {transform: translate(${sign1}100vw, ${sign2}100vw);}
`;

const slideInLargeK = (sign1, sign2) => keyframes`
  from {transform: translate(${sign1}100vw, ${sign2}100vw);}
  to {transform: translate(0, 0);}
`;

const SmallImage = styled.img`

`



const SlideOutLarge = styled.div`
  animation:  5s ${props => props.sign1 ? slideOutLargeK(props.sign1, props.sign2): null} ease-out ;
  animation-fill-mode: forwards;
  position: fixed;
  top: ${props => props.top ? props.top : "0"};
  right: ${props => props.right};
`

const SlideInLarge = styled.div`
  transform: translate(${props=>props.sign1}100vw, ${props=>props.sign2}100vw);
  animation:  ${props=>props.duration} ${props => props.sign1 ? slideInLargeK(props.sign1, props.sign2): null} ease-out ;
  animation-fill-mode: forwards;
  animation-delay: ${components.nav.images.start};
`

const slideOut = (sign) => keyframes`
0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(${sign}100vw, 0);
  }
`;

const slideIn = (sign) => keyframes`
0% {
    transform: translate(${sign}100vw, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const HomeImageOut = styled.div`
  animation: ${props => props.duration ? props.duration : '5s'} ${props => props.left ? slideOut('+') : slideOut('-')}  ease-out;
  animation-fill-mode: forwards;
`

const HomeImageIn = styled.div`
  animation: ${props => props.duration ? props.duration : '10s'} ${props => props.left ? slideIn('+') : slideIn('-')}  ease-out;
  animation-fill-mode: forwards;
  transform: translate(${props => props.left ? "+":"-"}100vw, 0);
`

const HomeLargeContainer = styled.div`
  position: fixed;
  z-index: 1;
`

const HomeSmallContainer = styled(FullWidth)`
  z-index: -10;
`

const HomeLargeSubContainer = styled.img`
    width: 50vw;
    height:${props => props.height ? props.height : "50vh"};
    object-fit: cover;
    transform: translate(0px, 0px);
    transition: transform 1s ease-in-out;
    box-shadow: -23px 10px 185px -47px rgba(0,0,0,0);
    &:hover {
        transform: translate(${props => props.xsign ? props.xsign : "+"}50px, ${props => props.ysign ? props.ysign : "+"}50px);
        box-shadow: -23px 10px 185px -47px rgba(0,0,0,1);
    }
`

const ProjectContainer = styled.div`
  position: fixed;
  width: 100vw;
  animation: ${AppearKeyFrame};
  animation-duration: ${components.nav.images.duration};
  animation-delay: 3s;
  opacity: 0;
  animation-fill-mode: forwards;
`

const Home = ({projects}) => {
    const chance = new Chance(1234561);
    const data = useSelector(state => state.data);
    const ui = useSelector(state => state.ui);
    const dispatch = useDispatch();
    const [redirecter, setRedirecter] = useState(null);
    const [early, setIsEarly] = useState(true);
    const [showSmallLines, setShowSmallLines] = useState(true);
    let prevnavstate = String(ui.navstate);

    useEffect(() => {
      setTimeout(() => {
        setIsEarly(false);
      }, 2000);

      if (prevnavstate !== ui.navstate){
        if (ui.navstate == NAVSTATES.DEFAULT){
          setTimeout(() => {
            setShowSmallLines(true);
          }, 300);
        } else {
          setShowSmallLines(false);
        }
        prevnavstate = String(ui.navstate);
      }
    });

    const selectProject = (project, idx)=>{
      dispatch({type: DATA_ACTIONS.SET_SELECTED_PROJECT, index: idx})
      dispatch(({type: UI_ACTIONS.SET_APPEAR, time: new Date()}))
    }

    //small content
    const getSmall = ()=>{
      let lines = (
        <Section style={{width: (window.innerWidth)+'px', zIndex: -1}}>
          <Line style={{ position: "fixed", left: "15px", top: parseInt(components.nav.height)+200+'px',  right:"15px", zIndex: showSmallLines?1:-1}}/>
          <Line style={{ position: "fixed", left: "15px", top: parseInt(components.nav.height)+200*2+'px',  right:"15px", zIndex: showSmallLines?1:-1}}/>
          <Line style={{ position: "fixed", left: "15px", top: parseInt(components.nav.height)+200*3+'px', right:"15px", zIndex: showSmallLines?1:-1}}/>
          <Line style={{ position: "fixed", left: "15px", top: parseInt(components.nav.height)+200*4+'px', right:"15px", zIndex: showSmallLines?1:-1}}/>
        </Section>
      )

      lines = projects.map((project, idx)=><Line key={idx} style={{ position: "absolute", left: "15px", top: parseInt(components.nav.height)+200*(idx+1)+'px',  right:"15px", zIndex: showSmallLines?1:-1}}/>)


      let chance = new Chance(1348);
      
      if (data.selectProject !== null){
        lines = (<HomeImageOut 
            left={chance.bool()}
            duration={chance.integer({ min: 500, max: 1000})+'ms'}>
              {lines}
          </HomeImageOut>
        )
      }

      let images = projects.map((project, idx)=>{
        const immer = 
        <Section key={idx}>
          <img alt={project.description}
            src={project.thumb} 
            onClick={()=>{
                selectProject(project, idx);
                setTimeout(() => {
                  setRedirecter('/project/'+project.name.toLowerCase())
                }, 1000);
            }}
            style={{
                width: "100%", 
                height: '200px', 
                maxHeight: '300px',
                objectFit: "cover", 
                float: "left",
            }}/>
            </Section>

            let rv = null;

            if (data.selectProject !== null){
              rv = (
                <HomeImageOut 
                  key={idx} 
                  left={chance.bool()}
                  duration={((data.projects[data.selectProject].name===project.name)?1200:chance.integer({ min: 500, max: 1000}))+'ms'}>
                    {immer}
                </HomeImageOut>
              )
            } else if (early){
              rv = (
                <HomeImageIn 
                key={idx} 
                left={chance.bool()}
                duration={chance.integer({ min: 1000, max: 2000})+'ms'}>
                  {immer }
              </HomeImageIn>
              )
            } else {
              rv= (<Section key={idx}>
                {immer}
              </Section>)
            }

            return (
              <Section key={idx} >
                {rv}
              </Section>
            )

          }
      )   

        return <Section>{lines}{images}</Section>;
    }

    //large content

    const getLarge = ()=>{
      let rv = [];
        
        const addImage = (idx, top, right, xsign, ysign)=>{
            let project = projects[idx];
            rv.push(
              <Section  key={idx} 
                        onClick={()=>{
                          selectProject(project, idx);
                          setTimeout(() => {
                            setRedirecter('/project/'+project.name.toLowerCase())
                          }, 1000)}}>
                <SlideOutLarge 
                  sign1={(data.selectProject == null)?null:xsign}
                  sign2={(data.selectProject == null)?null:ysign}
                  key={idx}
                  top={top+"px"}
                  right={right}
                  onMouseEnter = {()=>{
                      dispatch({type: UI_ACTIONS.SET_PROJ_HOVER, index: idx})
                  }}

                  onMouseLeave = {()=>{
                    // if (ui.projectHover === idx){
                    //   dispatch({type: UI_ACTIONS.SET_PROJ_HOVER, index: null})
                    // }
                  }}
                  >
                    <SlideInLarge
                      duration={chance.integer({ min: components.nav.images.durationMin, max: components.nav.images.durationMax})+'ms'}
                      sign1={xsign}
                      sign2={ysign}>
                      <HomeLargeSubContainer 
                        alt={project.description}
                        src={project.thumb} 
                        height={(window.innerHeight - components.nav.height)/2}
                        xsign={xsign}
                        ysign={ysign}
                        onClick={()=>{
                          selectProject(project, idx);
                        }}/>
                  </SlideInLarge>
                </SlideOutLarge>
              </Section>
            )
        }
        const heighter=Math.floor(window.innerHeight/2 + components.nav.height/2);
        addImage(0, 0, '50vw', "-", "-");
        addImage(1, 0, '0px', "+", "-");
        addImage(2, heighter, '50vw', "-", "+");
        addImage(3, heighter, '0px', "+", "+");
        return rv;
    }
    return (
        <Section>
            {redirecter!==null?
              <Redirect to={redirecter} />
              :
              <Section>
            <OnlySmall>
                <HomeSmallContainer>
                    {getSmall()}
                </HomeSmallContainer>
                <Project project={projects[data.selectProject || 0]}/>
            </OnlySmall>
            <OnlyLarge>
              <HomeLargeContainer>
                  {getLarge()} 
              </HomeLargeContainer>
              <ProjectContainer>
                <Project project={projects[ui.projectHover || 0]}/> 
              </ProjectContainer>
            </OnlyLarge>
            </Section>
            }
    </Section>)
}

const HomeQuery = () => {
  const data = useSelector(state => state.data);
  if (data.fetchstatus === DATA_STATES.DONE){
    return (<Home projects={data.projects} />)
  } else {
    return <Loader />
  }    
}

export default HomeQuery;

