import React, {useState, useEffect} from 'react';
import {Title, FullWidth, Center} from "./Theme"


const Loader = () => {
    const [stringer, setStringer] = useState('.');
    
    useEffect(() => {
        let interval = setInterval(() => {
            setStringer(stringer + '.');
        }, 500);

        return ()=>{
            clearInterval(interval);
        }
    });

    return (
        <FullWidth>
            <Center>
                {/* <Title>{stringer}</Title> */}
            </Center>
        </FullWidth>
)}


export default Loader;
