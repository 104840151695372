import React, { useState, useEffect, useRef } from 'react';
import styled, {keyframes} from 'styled-components';
import {medias, padding, components, colors} from '../theme.json'
import { Link } from 'react-router-dom';
import Expand from 'react-expand-animated';
import {Background, AppearKeyFrame, Title, Section, Icon, Line, underliner, FullWidth, FullHeight, CenterDiv, OnlyLarge, OnlySmall, VerticalCenter, Center, DisAppearKeyFrame} from "./Theme";
import AboutQuery from './About';
import ProjectListQuery from './ProjectList'
import Logo, {NakedLogo} from '../components/Logo'
import escape from '../assets/icons/escape.svg';
import escapeFat from '../assets/icons/escapeFat.svg';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as UI_ACTIONS, NAVSTATES} from "../reducers/UiReducer"
import { ACTIONS as DATA_ACTIONS} from "../reducers/DataReducer"

const Disser = styled.div`
animation: ${DisAppearKeyFrame};
animation-duration: 300ms;
animation-delay: 500ms;
animation-fill-mode: forwards;
opacity: 1.0;
`

const NavSuperContainer = styled.div`
  @media ${medias.OnlyLarge} {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px; 
  }
  z-index: 10;
  pointer-events: none;
`

const NavContainer = styled.div`
  background: ${colors.primary}
  position: absolute;
  @media ${medias.OnlyLarge} {
    transform: translate(0, 0px);
    width:100%;
  }
  z-index: 10;
  pointer-events: auto;
`

const NavContainerLarge = styled(NavContainer)`
    top: 50%;
`

const NavContainerSmall = styled(NavContainer)`
    zIndex: 10;
`

const NavBar = styled.div`
  height: ${components.nav.height}px;
  width: 100%;
  background: ${colors.primary};
  position: relative;
  text-align: center;
  z-index: 10;
`

const NavSubtitle = styled(VerticalCenter)`
    ${underliner}
  font-family: 'Futura LT Book', 'FuturaLTPro-Medium', Arial, Helvetica, sans-serif;
  font-size: 13.8px;
  @media ${medias.onlySmall} {
    font-size: 12px;
  }
  text-transform: uppercase;
  letter-spacing: 0.2em;
  z-index: 1;
  animation: ${props=>((props.animate==true)?AppearKeyFrame:null)};
  animation-duration: ${components.nav.sides.duration};
  animation-delay: ${props=>props.delay};
  opacity: ${props=>((props.animate==true)?0:100)};
  animation-fill-mode: forwards;
`

const LeftNavTitle = styled(NavSubtitle)`
  left: ${padding.default*1.5}px;
  @media ${medias.onlySmall} {
    left: ${0}px;
  }
`

const LeftNavClickBox = styled(VerticalCenter)`
    left: ${padding.default-20}px;
    width: 100px;
    height: 100%;
`

const RightNavClickBox = styled(VerticalCenter)`
    right: ${padding.default-10}px;
    width: 110px;
    height: 100%;
`

const RightNavTitle = styled(NavSubtitle)`
  right: ${padding.default*1.5}px; 
  @media ${medias.onlySmall} {
    right: ${0}px;
  }
`
//illias title


const NavTitleContainer = styled(VerticalCenter)`
  left: calc(50vw + ${props=>props.adder?props.adder:"-74px"});
  z-index: 0;
  animation: ${AppearKeyFrame};
  animation-duration: ${components.nav.title.duration};
  animation-delay: ${components.nav.title.start};
  opacity: 0;
  animation-fill-mode: forwards;
`

const NavTitleContainerTop = styled(NavTitleContainer)`
  left: 0;
  right: 0;
`

const NavTitleContainerSmall = styled(NavTitleContainer)`
  left: 0;
  right: 0;
  text-align: center
`
const NavCloseRight = styled.div`
  position: absolute;
  right 20px;
  top: ${(components.nav.height - components.icon.height)/2}px;
`
const CloseLargeContainer = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    z-index:200;
`

const PNAK = keyframes`
  from {
        transform: scale(0, 0);
    }
  to {
        transform: scale(1, 1);
    }
`

const ProjectNameAppear = styled.div`
    animation: ${PNAK};
    animation-duration: 2s;
    animation-fill-mode: forwards;
    transform: scale(0, 0);
`

const Navigation = () => {
    const [positioner, setPositioner] = useState(components.nav.height);
    const expandDuration = parseInt(components.nav.expandDuration);
    const refContainer = useRef(null);
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const data = useSelector(state => state.data);
    const [delayer, setDelayer] = useState(components.nav.sides.start);
    const [useDistance, setUseDistance] = useState(0);
    const [shouldAnimate, setShouldAnimate] = useState(true);
    const [urlLoc, setUrlLoc] = useState("/");
    const [shouldHideHover, setShouldHideHover] = useState(false);
    let distancer = 1000;
    let divider = 13;
    const setContentSelector = (newNavState)=>{
        dispatch({ type: UI_ACTIONS.SET_NAV,  nav: newNavState});
        setDelayer('1s');
    }

    useEffect(() => {

        let newShouldAnimate = (window.location.href.indexOf("project") === -1);
        if (shouldAnimate  !== newShouldAnimate){
            setShouldAnimate(newShouldAnimate);
        }

        if (window.location.pathname !== urlLoc){
            setUrlLoc(window.location.pathname);
            dispatch({type: UI_ACTIONS.SET_CHANGE_PAGE, changed: true});
            setTimeout(() => {
                dispatch({type: UI_ACTIONS.SET_CHANGE_PAGE, changed: false});
            }, 1000);
        }

        if (ui.appear !== null){
            var id = setInterval(()=>{
                setUseDistance(Math.max(Math.floor((distancer-((new Date()) - ui.appear))/divider),0))
                if((new Date()) - ui.appear > distancer) {
                    clearInterval(id);
                    dispatch(({type: UI_ACTIONS.SET_APPEAR, time: null}))
                }
            }, 20);   
        }
        
        const checker = ()=>{
            if (refContainer && refContainer.current){
                let nv = refContainer.current.clientHeight;
                if( nv !== positioner){
                    setPositioner(nv);
                }
            }
        }
        setTimeout(() => {
            checker();
        }, 50);
        setTimeout(() => {
            checker();
        }, 101);
        setTimeout(() => {
            checker();
        }, 21);
    });
    
    const centernav = (
            <Section>
            <OnlySmall>
                <NavTitleContainerSmall>
                    <Link to='/' onClick={()=>{
                        dispatch({type: DATA_ACTIONS.SET_SELECTED_PROJECT, index: null})
                        dispatch({type: UI_ACTIONS.SET_PROJ_HOVER, index: null})
                        setDelayer(components.nav.sides.start);
                    }}>
                        <Title>
                            Illias Teirlinck 
                        </Title>
                    </Link>
                </NavTitleContainerSmall>
                    <FullWidth style={{paddingTop:components.nav.height+"px"}}>
                        {data.selectProject!==null? 
                            <Center style={{transform: 'translate(0px,-11px)'}}>
                                <Title >
                                    {data.projects[data.selectProject].name}
                                </Title>
                            </Center>: null}
                        <Line />
                    </FullWidth> 
            </OnlySmall>
            <OnlyLarge>
                {data.selectProject!==null?
                    <NavTitleContainerTop>
                        <Link to='/' onClick={()=>{
                        dispatch({type: DATA_ACTIONS.SET_SELECTED_PROJECT, index: null})
                        dispatch({type: UI_ACTIONS.SET_PROJ_HOVER, index: null})
                        setDelayer(components.nav.sides.start);
                    }}>
                        <Title>
                        Illias Teirlinck, {data.projects[data.selectProject].name}
                        </Title>
                    </Link>

                    </NavTitleContainerTop>:
                <NavTitleContainer>
                    <Link to='/' onClick={()=>{
                        dispatch({type: DATA_ACTIONS.SET_SELECTED_PROJECT, index: null})
                        dispatch({type: UI_ACTIONS.SET_PROJ_HOVER, index: null})
                        setDelayer(components.nav.sides.start);
                    }}>
                        <Title>
                            Illias Teirlinck 
                        </Title>
                    </Link>
                </NavTitleContainer>}
                <NavTitleContainer adder="64px">
                    <Title>
                        {(ui.projectHover!==null && !shouldHideHover && data.selectProject===null)? ',\u00A0'+data.projects[ui.projectHover].name:null}
                    </Title>
                </NavTitleContainer>
            </OnlyLarge>
            </Section>)
        ;
    const sidenav = (
        <Section>
            <Section onClick={()=>{setContentSelector(NAVSTATES.ABOUT)}}>
                <LeftNavClickBox />
                <LeftNavTitle delay={delayer} animate={shouldAnimate}>
                    About
                </LeftNavTitle>
            </Section>
            <Section onClick={()=>{setContentSelector(NAVSTATES.PROJECTS)}}>
                <RightNavClickBox/>
                <RightNavTitle delay={delayer} animate={shouldAnimate}>
                    Projects
                </RightNavTitle>
            </Section>
        </Section>)

    const closeButton = (
        <Section>
            <OnlyLarge>
                <Icon onClick={()=>{setContentSelector(NAVSTATES.DEFAULT)}}>
                    <img src={escape} alt="escape"/>
                </Icon>
            </OnlyLarge>
            <OnlySmall>
                <Icon onClick={()=>{setContentSelector(NAVSTATES.DEFAULT)}} style={{width: '16px', marginTop: "20px", marginBottom:"20px"}}>
                    <img src={escapeFat} alt="escape"/>
                </Icon>
            </OnlySmall>
        </Section>
        
    )
    const fullCloseButton = (
        <Section>
            <OnlySmall>
                    <FullWidth>
                    <CenterDiv>
                        {closeButton}
                    </CenterDiv>
                </FullWidth>
            </OnlySmall>
            <OnlyLarge>
                <CloseLargeContainer>
                    {closeButton}
                </CloseLargeContainer>
            </OnlyLarge>
        </Section>
        
    )

    const LargeOverIcon = (()=>{
        if ((data.selectProject == null) && (ui.navstate!==NAVSTATES.ABOUT)){
            return  <Logo />;
        } else if (urlLoc == '/' && data.selectProject !==null){
            return <Disser><NakedLogo/></Disser>
        } else {
            return null;
        }
    })()

    
    
    let navbarcontentLarge = null, navbarcontentSmall = null; 
    switch (ui.navstate) {
        case NAVSTATES.PROJECTS:
            document.title = "Illias Teirlinck, projects"
            navbarcontentLarge = (
                <section>
                    <NavCloseRight>
                        {closeButton}
                    </NavCloseRight>
                    <div style={{width: "calc(100vw - 95px"}}>
                        <ProjectListQuery />
                    </div>
                    
                </section>
            )
            navbarcontentSmall =  (
                <section>
                    {sidenav}
                    {centernav}
                </section>
            )
            break;
        case NAVSTATES.ABOUT:
            document.title = "Illias Teirlinck, about"
            navbarcontentSmall =  (
                <section>
                    {sidenav}
                    {centernav}
                </section>
            )
            navbarcontentLarge = <Section>
                <Line/>
                {centernav}
            </Section>
            break;
        default:
            document.title = "Illias Teirlinck, photographer"
            navbarcontentLarge = navbarcontentSmall = (
                <section>
                    {sidenav}
                    {centernav}
                </section>
            )
            break;
    }
    
    const renderLarge = (
        <NavSuperContainer onMouseEnter={()=>{setShouldHideHover(true)}} onMouseLeave={()=>{setShouldHideHover(false)}}>
            <NavContainerLarge ref={refContainer} 
                style={{ transform: (data.selectProject==null)?
                            "translate(0, "+(window.innerHeight-positioner)/2+"px)"
                            :(ui.appear !== null )? "translate(0, -"+useDistance+"px)": null}}>
                <Background>
                    <NavBar>
                        {navbarcontentLarge}
                    </NavBar>
                    <Expand open={ui.navstate === NAVSTATES.ABOUT} duration={expandDuration}>
                        {fullCloseButton}
                        <AboutQuery />
                    </Expand>
                </Background>
                {(data.selectProject!==null && ui.navstate !== NAVSTATES.ABOUT)?<Line style={{marginLeft: padding.default*1.5+"px",marginRight: padding.default*1.5+'px'}}/>:null}
            </NavContainerLarge>
        </NavSuperContainer>
    )

    const renderSmall = (
        <NavContainerSmall>
            <NavBar style={{zIndex: 10}}>
                {navbarcontentSmall}
            </NavBar>
            <Background style={{position: "fixed", width: "100vw", transform: "translate(-15px, 0)", zIndex: 10, top: components.nav.height - 10}}>
                <Expand open={ui.navstate === NAVSTATES.PROJECTS} duration={expandDuration} style={{zIndex: 10}}>
                    {fullCloseButton}
                    <ProjectListQuery />
                </Expand>
                <Expand open={ui.navstate === NAVSTATES.ABOUT} duration={expandDuration} style={{zIndex: 10}}>
                    <FullHeight style={{minHeight: "1024px", width:"100vw", transform: "translate(-15px, 0)", background: "white"}}>
                        {fullCloseButton}
                        <AboutQuery />
                    </FullHeight>
                </Expand>
            </Background>
        </NavContainerSmall>
    )
    return (
        <Section>
            <OnlyLarge>
                {renderLarge}
                {LargeOverIcon}
            </OnlyLarge>
            <OnlySmall>
                {renderSmall}
            </OnlySmall> 
        </Section>
    );
}

export default Navigation;




