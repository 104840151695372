export const ACTIONS = Object.freeze({
    SET_NAV: Symbol("ui/setNavigationStatus"),
    SET_PROJ_HOVER: Symbol("ui/setProjectHover"),
    SET_APPEAR:Symbol("ui/setAppear"),
    SET_CHANGE_PAGE:Symbol("ui/setChangePage")
});

export const NAVSTATES = Object.freeze({
    DEFAULT:   Symbol("default"),
    ABOUT:  Symbol("about"),
    PROJECTS: Symbol("projects"),
});

const initialState = {
  navstate: NAVSTATES.DEFAULT,
  projectHover: null,
  appear:new Date(),
  changedPage: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_NAV: {
        return {
          ...state,
          navstate: action.nav
        };
      }
    case ACTIONS.SET_PROJ_HOVER: {
        return {
            ...state,
            projectHover: action.index
        };
    }
    case ACTIONS.SET_APPEAR: {
      return {
          ...state,
          appear: action.time
      };
  }
  case ACTIONS.SET_CHANGE_PAGE: {
    return {
        ...state,
        changedPage: action.changed
    };
}
    default: {
      return { ...state };
    }
  }
};
